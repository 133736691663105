.splitterContainer {
    @apply rounded-xxxlg pt-16 mt-16 -mx-16;
    @screen sm {
        @apply pt-12 mt-12 mx-0;
    }
}

.title {
    @apply col-span-12 heading6XL;

    @screen md {
        @apply col-span-8 heading4XL;
    }

    @screen sm {
        @apply col-span-4 heading3XL;
    }
}

.subtitle {
    @apply col-span-12 headingXL font-normal;

    @screen md {
        @apply col-span-8;
    }

    @screen sm {
        @apply col-span-4 headingL;
    }
}

.numberedSection2Cols {
    @apply col-span-6 pt-16 pb-12 border-t-1 border-black border-dashed;

    @screen md {
        @apply col-span-4;
    }

    @screen sm {
        @apply col-span-4;
    }
}

.numberedSection3Cols {
    @apply col-span-4 pt-16 pb-12 border-t-1 border-black border-dashed;

    @screen md {
        @apply col-span-8;
    }

    @screen sm {
        @apply col-span-4;
    }
}

.numberedSection4Cols {
    @apply col-span-3 pt-16 pb-12 border-t-1 border-black border-dashed;

    @screen md {
        @apply col-span-2;
    }

    @screen sm {
        @apply col-span-4;
    }
}

.number {
    @apply heading8XL;
}

.icon {
    @apply mb-4;
}

.infographic {
    @apply h-auto mb-6;
}

.headline {
    @apply contentL font-bold mb-4;
}

.body {
    @apply contentS;
}

.accentLemonDark {
    @apply bg-lemon-dark;
}

.accentLemonPastel {
    @apply bg-lemon-pastel;
}

.accentOrcaPastel {
    @apply bg-orca-pastel;
}

.accentOrcaDark {
    @apply bg-orca-dark text-white;

    .numberedSection2Cols,
    .numberedSection3Cols,
    .numberedSection4Cols {
        @apply border-white;
    }
}
