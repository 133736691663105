.inputWrapper {
    @apply bg-white inline-block font-italianplate rounded-xlg;

    &Small {
        @apply h-12;
    }
    &XSmall {
        @apply h-10;
    }

    @screen sm {
        @apply w-full;
    }
}

.input {
    @apply contentM duration-200 h-16 inline-block leading-normal outline-none pl-10 pr-4 pb-5 pt-6 rounded-xlg text-black transition-all w-full border-transparent border-0-1;

    background: url("../../../assets/images/icons/search-icon.svg") no-repeat 1rem;

    &:hover:not(.disabled),
    &:focus-within {
        @apply border-black;
    }

    &.Small {
        @apply h-12 leading-12;
    }

    &.XSmall {
        @apply h-10 leading-10;
    }

    &:disabled {
        @apply text-gray80;
        background: url("../../../assets/images/icons/disabled-search-icon.svg") no-repeat 1rem;
    }

    &::placeholder {
        @apply text-black;
    }

    @screen sm {
        /* scale up dimension values then scale down with transform-scale to workaround iOS input auto zoom
        referenced here - https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
        */
        @apply duration-200 transition-all inline-block pb-0 rounded-xxlg h-24 leading-8 origin-top-left;
        background: url("../../../assets/images/icons/search-icon.svg") no-repeat 1.333333333rem;
        --tw-text-opacity: 1.333333333;
        color: rgba(0, 0, 0, var(--tw-text-opacity));
        font-size: 1.166666667rem;
        outline: 2.666666669px solid transparent;
        outline-offset: 2.666666669px;
        padding-right: 1.333333333rem;
        padding-left: 3.333333333rem;
        padding-top: 0.166666667rem;
        width: 133.333333333%;
        transform: scale(0.75);

        &:hover:not(:focus-within):not(.disabled) {
            box-shadow: inset 0 0 0 0.666666667px rgba(0, 0, 0, 1);
            --tw-shadow: inset 0 0 0 0.666666667px rgba(0, 0, 0, 1);
        }

        &.Small {
            @apply h-16;
        }

        &.XSmall {
            height: 3.29rem;
        }

        &:disabled {
            @apply text-gray80;
            background: url("../../../assets/images/icons/disabled-search-icon.svg") no-repeat 1rem;
        }
    }
}
